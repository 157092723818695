<template>
    <div class="emulator">
        <div class="screen">
            <div class="status-bar"></div>
            <div class="title-bar">
                <h2>{{ title }}</h2>
            </div>
            <div class="page-content">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: "页面标题",
        },
    },
};
</script>
<style lang="less" scoped>
.emulator {
    width: 415px;
    background-color: #fff;
    border-radius: 59px;
    // box-shadow: 0px 16px 32px -11px rgba(0, 0, 0, 0.15);
    min-height: 100%;
    padding: 20px;
    display: flex;
    height: 100%;
    height: 100%;
    box-sizing: border-box;

    .screen {
        border: 1px solid #d4dbe1;
        height: 100%;
        border-radius: 40px;
        overflow: hidden;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
    }

    .status-bar {
        flex: 0 0 auto;
        height: 44px;
        background-image: url("../../../assets/status-bar.png");
        background-repeat: no-repeat;
        background-position: 24px 16px;
    }

    .title-bar {
        flex: 0 0 auto;
        height: 44px;
        background-image: url("../../../assets/wx-program.png");
        background-repeat: no-repeat;
        background-position: 282px 6px;

        h2 {
            height: 44px;
            line-height: 44px;
            font-size: 17px;
            margin: 0;
            text-align: center;
        }
    }

    .page-content {
        flex: 1 1 auto;
        border-top: 1px solid #e5e5e5;
        background-color: #f2f4f7;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }
    }
}
</style>