import request from '@/util/request';
import qs from "qs";

export function getList(query) {
    return request({
        url: '/biz-card-template',
        method: 'get',
        params: query
    });
};

export function get(id) {
    return request({
        url: `/biz-card-template/${id}`,
        method: 'get'
    });
}

export function getCompanyName() {
    return request({
        url: `/biz-card-template/company-name`,
        method: 'get'
    });
}

export function save(data) {
    return request({
        url: '/biz-card-template',
        method: 'post',
        data: qs.stringify(data)
    });
}

export function remove(id) {
    return request({
        url: `/biz-card-template/${id}`,
        method: 'delete'
    });
}