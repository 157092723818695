<template>
    <Emulator title="林某某">
        <div class="top-bg">
            <div class="card" :style="{ backgroundColor: templateInfo.backgroundColor, color: templateInfo.foreColor }">
                <div class="top-info">
                    <img src="../../../assets/avatar/avatar0.png" />
                    <div class="items">
                        <h2>Linkflow小犀</h2>
                        <h3>售前咨询</h3>
                        <h4>{{ companyName ? companyName : "未设置公司名称" }}</h4>
                    </div>
                </div>
                <div class="info-item"><i class="nazaio-iconfont nz-mobile" /><span>187xxxx6789</span></div>
                <div class="info-item"><i class="nazaio-iconfont nz-mail" /><span>example@163.com</span></div>
                <div class="info-item"><i class="nazaio-iconfont nz-analy-area" /><span>上海市徐汇区漕溪北路xxx号xxx室</span></div>
            </div>
            <div class="actions">
                <div class="add-contact">存入通讯录</div>
                <div class="share">分享名片</div>
            </div>
            <div class="pv-info">
                <div class="avatar-list">
                    <img src="../../../assets/avatar/avatar2.png" />
                    <img src="../../../assets/avatar/avatar3.png" />
                    <img src="../../../assets/avatar/avatar4.png" />
                    <img src="../../../assets/avatar/avatar5.png" />
                    <img src="../../../assets/avatar/avatar6.png" />
                    <div class="more"><i class="nazaio-iconfont nz-more-horiz" /></div>
                </div>
                <div class="pv">190人浏览</div>
            </div>
        </div>
        <div class="section contact-items">
            <div class="contact-item mobile">
                <h2>手机号</h2>
                <h3>187xxxx6789</h3>
            </div>
            <div class="contact-item mail">
                <h2>邮箱</h2>
                <h3>example@163.com</h3>
            </div>
            <div class="contact-item wechat">
                <h2>微信号</h2>
                <h3>wechatnumber</h3>
            </div>
        </div>
        <div class="section recommend-knowledge" v-if="templateInfo.knowledgeShelfList.length > 0">
            <h1>推荐资料</h1>
            <ul>
                <li v-for="k in templateInfo.knowledgeShelfList" :key="k.id">
                    <img :src="k.coverImg" />
                    <h2>{{ k.name }}</h2>
                </li>
            </ul>
        </div>
        <div class="section company-intro">
            <h1>公司简介</h1>
            <div class="rich-text-wrapper"><span v-html="companyIntro"></span></div>
        </div>
        <div class="section personal-intro">
            <h1>个人简介</h1>
            <div class="rich-text-wrapper">
                <p>
                    大家好我是一只来自运营星球正在努力工作的小犀牛，我的名字来源于 “上海源犀信息科技有限公司”中的犀字。Boss说我是世界上发现的唯一的独角兽哦，也是Linkflow的专属IP形象。在Linkflow的日子里，我会一直陪在你身边。很高兴认识大家，希望大家喜欢我呦~
                </p>
            </div>
        </div>
    </Emulator>
</template>
<script>
import { get } from "@/api/card-template";
import Emulator from "./Emulator";

export default {
    components: {
        Emulator,
    },
    props: {
        companyName: {
            type: String,
            default: "",
        },
        companyIntro: {
            type: String,
            default: ""
        },
        previewCardId: {
            type: Number,
            default: 0,
        },
        template: {
            type: Object,
            default: null,
        },
    },
    created() {
        if (this.previewCardId) {
            this.loadInfo(this.previewCardId);
        }
        if (this.template != null) {
            this.templateInfo = this.template;
        }
    },
    data() {
        return {
            templateInfo: {
                id: 0,
                name: "",
                backgroundColor: "#fff",
                foreColor: "#000",
                defaultTemplate: false,
                knowledgeShelfList: [],
            },
        };
    },
    watch: {
        previewCardId(val) {
            this.loadInfo(val);
        },
    },
    methods: {
        loadInfo(val) {
            if (!val) {
                return;
            }
            get(val).then((response) => {
                this.templateInfo.name = response.name;
                this.templateInfo.defaultTemplate = response.defaultTemplate;
                this.templateInfo.backgroundColor = response.backgroundColor;
                this.templateInfo.foreColor = response.foreColor;
                this.templateInfo.knowledgeShelfList = response.knowledgeShelfList || [];
            });
        },
    },
};
</script>
<style lang="less" scoped>
.top-bg {
    background-color: #fff;
    overflow: hidden;
}

.card {
    width: 343px;
    margin: 20px auto 0 auto;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.1), 0px 2px 8px -2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    color: #000;

    .top-info {
        display: flex;
        margin-bottom: 13px;

        img {
            width: 88px;
            height: 88px;
            border-radius: 8px;
        }
    }

    .items {
        width: 195px;
        flex: 1 1 195px;
        margin-left: 20px;

        h2 {
            font-weight: 500;
            font-size: 22px;
            text-align: left;
            height: 30px;
            margin: 0;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        h3 {
            font-size: 14px;
            margin-top: 4px;
            height: 20px;
            line-height: 20px;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        h4 {
            font-size: 12px;
            margin-top: 16px;
            line-height: 18px;
            font-weight: normal;
        }
    }

    .info-item {
        display: flex;
        line-height: 18px;
        font-size: 12px;
        margin: 3px 0;

        i {
            font-size: 12px;
        }

        span {
            margin-left: 8px;
        }
    }
}

.actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    text-align: center;

    .add-contact {
        width: 163px;
        height: 40px;
        line-height: 40px;
        background-color: #f2f2f2;
        color: #07c160;
        border-radius: 4px;
    }

    .share {
        width: 163px;
        height: 40px;
        line-height: 40px;
        background-color: #07c160;
        color: #fff;
        border-radius: 4px;
    }
}

.pv-info {
    margin: 20px 16px 16px 16px;
    display: flex;
    justify-content: space-between;

    .avatar-list {
        white-space: nowrap;

        img,
        .more {
            font-size: 0;
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            overflow: hidden;
            margin-right: 6px;
        }

        .more {
            box-sizing: border-box;
            border: 1px solid #979797;
            text-align: center;
            overflow: hidden;

            i {
                font-size: 15px;
            }
        }
    }

    .pv {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        height: 24px;
        line-height: 24px;
    }
}

.section {
    width: 351px;
    background: #ffffff;
    border-radius: 8px;
    margin: 12px auto 0 12px;
    padding: 12px;

    &:last-child {
        margin-bottom: 16px;
    }

    h1 {
        font-size: 17px;
        margin: 0;
        height: 24px;
        line-height: 24px;
        margin-bottom: 12px;
    }
}

.contact-items {
    white-space: nowrap;
    overflow-y: auto;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
        border-radius: 3px;
    }

    .contact-item {
        display: inline-block;
        margin-right: 8px;
        background-color: #f5f7fa;
        padding: 12px;
        background-repeat: no-repeat;
        background-size: 28px 28px;
        background-position: calc(100% - 10px) 6px;

        &:last-child {
            margin-right: 0;
        }

        h2 {
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            height: 20px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
        }

        h3 {
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            height: 18px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .mobile {
        background-image: url("../../../assets/e-card-phone-number.png");
    }

    .mail {
        background-image: url("../../../assets/e-card-email.png");
    }

    .wechat {
        background-image: url("../../../assets/e-card-wechat.png");
    }
}

.recommend-knowledge {
    overflow-y: auto;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
        border-radius: 3px;
    }

    ul {
        white-space: nowrap;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 12px;
            vertical-align: top;

            img {
                display: block;
                width: 144px;
                height: 96px;
                border-radius: 2px;
                object-fit: cover;
            }

            h2 {
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                margin-top: 8px;
                width: 144px;
                white-space: break-spaces;
                word-break: break-all;
            }
        }
    }
}

.rich-text-wrapper {
    white-space: break-spaces;
    word-break: break-all;

    /deep/ img {
        max-width: 100%;
        display: block;
        margin: 10px auto;
    }
}
</style>